<div *ngIf="isStagingEnvironment" class="staging-banner">
  <div class="staging_label_area">
<!--    <div class="staging_label top_staging_label">-->
<!--      <div class="staging_label_content left_label">STAGING</div>-->
<!--      <div class="staging_label_content right_label">STAGING</div>-->
<!--    </div>-->
    <div class="bottom_staging_label">
      <div class="staging_label_content left_label">STAGING</div>
      <div class="staging_label_content right_label">STAGING</div>
    </div>
  </div>
</div>
<app-screen-lock></app-screen-lock>
<ng-container *ngIf="isAuthed()">
  <app-screen-layout></app-screen-layout>
</ng-container>

<div *ngIf="!(isAuthed())">
  Please Login in
</div>
