import { Component, OnInit } from '@angular/core';
import {SessionDataService} from './services/session-data.service';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'gaia-angular';
  isStagingEnvironment = environment.staging === true;

  constructor(
    private sessionData: SessionDataService,
  ){
  }

  ngOnInit(){
  }

  isAuthed(): boolean {
    return this.sessionData.authenticationState.getValue();
  }

}

